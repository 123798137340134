













































































import {
  Component,
  Getter,
  State,
  Provide,
  mixins,
  namespace
} from 'nuxt-property-decorator'
import { NuxtVueI18n } from 'nuxt-i18n'
import { DateTimeFormatOptions } from 'luxon'

import { State as RootState } from '~/store/state'
import { Getters } from '~/store/backend/getters'

import { DisplaysAlerts } from '~/assets/mixins'

import Logo from '~/components/Logo.vue'
import AlertContainer from '~/components/layout/AlertContainer.vue'

export type SetStatusMessageFn = (message?: string) => void
export type SetLoadingFn = (loading?: boolean) => void

const backend = namespace('backend')

@Component<DefaultLayout>({
  components: {
    Logo,
    AlertContainer
  }
})
export default class DefaultLayout extends mixins(DisplaysAlerts) {
  statusMessage: string = ''

  loading = false

  @State readonly device!: RootState['device']
  @State readonly progress!: RootState['progress']
  @State(state => state.battery.level) readonly batteryLevel!: number
  @backend.Getter readonly user!: Getters['user']

  @Getter readonly currentDate!: (
    format?: DateTimeFormatOptions,
    locale?: 'de' | 'it'
  ) => string
  @Getter readonly currentTime!: (locale?: 'de' | 'it') => string

  get availableLocales() {
    return this.$i18n.locales!.filter(
      locale => typeof locale === 'object' && locale.code !== this.$i18n.locale
    ) as NuxtVueI18n.Options.LocaleObject[]
  }

  get showClockControl() {
    return process.env.SHOW_CLOCK_CONTROL === 'on'
  }

  get battery() {
    const step = Math.floor(this.batteryLevel / 10) * 10
    return {
      color:
        this.batteryLevel >= 75
          ? 'success'
          : this.batteryLevel >= 50
          ? 'info'
          : this.batteryLevel >= 25
          ? 'warning'
          : 'error',
      icon: step === 100 ? '' : `-${step}`
    }
  }

  @Provide()
  setStatusMessage(message?: string) {
    this.statusMessage = message || ''
  }

  @Provide()
  setLoading(loading?: boolean) {
    this.loading = typeof loading === 'boolean' ? loading : !this.loading
  }

  @State readonly clock!: NodeJS.Timeout
  __toggleClock() {
    if (this.$store.state.clock) {
      this.$store.dispatch('stopClock')
    } else {
      this.$store.dispatch('startClock')
    }
  }
}
