














import { Component, Prop, Vue } from 'nuxt-property-decorator'

@Component
export default class Logo extends Vue {
  @Prop({
    type: [String, Boolean],
    default: true
  })
  readonly link!: string | boolean

  get linkActual() {
    return typeof this.link === 'string'
      ? this.link
      : this.link
      ? this.localePath('index')
      : null
  }
}
