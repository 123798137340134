import Vue from 'vue'

import constants from '~/assets/constants.json'
import packageInfo from '~/package.json'

declare module 'vue/types/vue' {
  interface Vue {
    $constants: typeof constants
    $appVersion: string
  }
}

Vue.prototype.$constants = constants
Vue.prototype.$appVersion = packageInfo.version
