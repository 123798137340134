import { MutationTree } from 'vuex'
import { SHA256 } from 'crypto-js'

import initState, { State, CourseDateReference } from './state'

import { User, Course, LicenseHolder, CourseDate } from '~/assets/models'
import { RemoteUser } from '~/assets/models/user'

export const RESET_STATE = 'resetState'

export const AUTHORIZE_USER = 'authorizeUser'
export const CLEAR_AUTHORIZATION = 'clearAuthorization'

export const UPDATE_USER = 'updateUser'
export const REMOVE_USER = 'removeUser'

export const SET_PRINTERS = 'setPrinters'
export const SET_DEFAULT_PRINTER = 'setDefaultPrinter'

export const SET_LOCATIONS = 'setLocations'
export const SET_SELECTED_LOCATION = 'setSelectedLocation'

export const UPDATE_LICENSE_HOLDER = 'updateLicenseHolder'
export const REMOVE_LICENSE_HOLDERS = 'purgeLicenseHolders'

export const UPDATE_COURSE = 'updateCourse'
export const REMOVE_COURSE = 'removeCourse'

export const SET_ACTIVE_COURSE_DATES = 'setActiveCourseDates'
export const COMPLETE_COURSE_DATE = 'completeCourseDate'

export const SET_RESYNC_THROTTLE = 'setResyncThrottle'

const mutations: MutationTree<State> = {
  [AUTHORIZE_USER]: (state, code: string) => {
    const hash = SHA256(code).toString()
    const user = state.users.find(u => u.pin === hash)

    if (user) {
      state.authorized = user.id
    } else {
      state.authorized = null
    }
  },

  [CLEAR_AUTHORIZATION]: state => {
    state.authorized = null
  },

  [UPDATE_USER]: (state, user: RemoteUser | User) => {
    const other = state.users.filter(u => u.id !== user.id)
    const local = state.users.find(u => u.id === user.id)

    state.users = [
      ...other,
      {
        ...(local || { _courses: [] }),
        ...user
      }
    ]
  },

  [REMOVE_USER]: (state, user: RemoteUser | User) => {
    state.users = state.users.filter(u => u.id !== user.id)
  },

  [UPDATE_LICENSE_HOLDER]: (state, holder: LicenseHolder) => {
    const other = state.licenseHolders.filter(lh => lh.id !== holder.id)
    const local = state.licenseHolders.find(lh => lh.id === holder.id)

    state.licenseHolders = [
      ...other,
      {
        ...local,
        ...holder
      }
    ]
  },

  [UPDATE_COURSE]: (state, course: Course) => {
    const other = state.courses.filter(c => c.id !== course.id)
    const local = state.courses.find(c => c.id === course.id)

    state.courses = [
      ...other,
      {
        ...local,
        ...course
      }
    ]
  },

  [REMOVE_COURSE]: (state, course: Course) => {
    state.courses = state.courses.filter(c => c.id !== course.id)
  },

  [SET_ACTIVE_COURSE_DATES]: (state, active: CourseDateReference[]) => {
    state.activeDates = active
  },

  [COMPLETE_COURSE_DATE]: (
    state,
    { courseId, date }: { courseId: number; date: CourseDate }
  ) => {
    const course = state.courses.find(c => c.id === courseId)
    if (!course) {
      return false
    }

    course.course_dates = course.course_dates.filter(cd => cd.id !== date.id)
    state.completeDates.push(date.id)

    if (!course.course_dates.length) {
      state.courses = state.courses.filter(c => c.id !== course.id)
      state.completeCourses.push(course.id)
    }

    return true
  },

  [REMOVE_LICENSE_HOLDERS]: (state, ids: number[]) => {
    state.licenseHolders = state.licenseHolders.filter(
      lh => !ids.includes(lh.id)
    )
  },
  [SET_RESYNC_THROTTLE]: (state, force?: boolean) => {
    state.resyncThrottle =
      typeof force === 'boolean' ? force : !state.resyncThrottle
  },
  [SET_PRINTERS]: (state, printers) => {
    state.printers = printers
  },
  [SET_DEFAULT_PRINTER]: (
    state,
    { id, type }: { id: number; type: 'card' | 'paper' }
  ) => {
    if (typeof state.defaultPrinter[type] === 'undefined') {
      throw new TypeError(`Cannot set default printer for type ${type}`)
    }

    state.defaultPrinter[type] = id
  },
  [SET_LOCATIONS]: (state, locations) => {
    state.locations = locations
  },
  [SET_SELECTED_LOCATION]: (state, location) => {
    state.selectedLocation = location
  },
  [RESET_STATE]: state => {
    const {
      completeCourses,
      completeDates,
      courses,
      licenseHolders,
      printers,
      defaultPrinter,
      resyncThrottle
    } = initState()

    state.completeCourses = completeCourses
    state.completeDates = completeDates
    state.courses = courses
    state.licenseHolders = licenseHolders
    state.printers = printers
    state.defaultPrinter = defaultPrinter
    state.resyncThrottle = resyncThrottle
  }
}

export default mutations
