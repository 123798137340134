import { Context } from '@nuxt/types'
import colors from 'vuetify/es5/util/colors'

export default ({ app }: Context) => {
  return {
    lang: {
      t: (key: string, ...params: any[]) => app.i18n.t(key, params)
    },
    theme: {
      themes: {
        light: {
          primary: '#244793',
          secondary: colors.grey.base,
          accent: '#4caf50',
          info: colors.teal.lighten1,
          warning: colors.amber.base,
          error: colors.deepOrange.accent4,
          success: '#4caf50'
        }
      }
    }
  }
}
