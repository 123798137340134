import { Component, Vue } from 'nuxt-property-decorator'
import { DateTime, DateTimeFormatOptions, LocaleOptions } from 'luxon'

export const defaultDateFormat = {
  ...DateTime.DATE_SHORT,
  month: '2-digit',
  day: '2-digit'
} as DateTimeFormatOptions & LocaleOptions

@Component
export default class FormatsDates extends Vue {
  private getDateTime(input: number | string) {
    return typeof input === 'string'
      ? this.$dateTime.fromISO(input)
      : this.$dateTime.fromMillis(input)
  }

  formatDate(
    date: number | string,
    format?: DateTimeFormatOptions & LocaleOptions
  ) {
    const dateTime = this.getDateTime(date)

    return dateTime
      .setLocale(this.$i18n.locale)
      .toLocaleString(format || defaultDateFormat)
  }

  formatTime(
    date: number | string,
    format?: DateTimeFormatOptions & LocaleOptions
  ) {
    const dateTime = this.getDateTime(date)

    return dateTime
      .setLocale(this.$i18n.locale)
      .toLocaleString(
        format ||
          (this.$dateTime.TIME_24_SIMPLE as DateTimeFormatOptions &
            LocaleOptions)
      )
  }

  getDayOffset(dateA: number | string | null, dateB?: number | string) {
    const dateTimeA = dateA ? this.getDateTime(dateA) : this.$dateTime.local()
    const dateTimeB = dateB ? this.getDateTime(dateB) : this.$dateTime.local()

    return Math.floor(dateTimeB.diff(dateTimeA, 'days').as('days'))
  }
}
