import { GetterTree } from 'vuex'
import { DateTime, DateTimeFormatOptions, LocaleOptions } from 'luxon'

import { State } from './state'
import { Locale } from '~/assets/models/system'

import { defaultDateFormat } from '~/assets/mixins/FormatsDates'

const getLocalizedNow = (state: State, locale?: Locale) => {
  if (!state.now) {
    return null
  }

  if (locale) {
    return state.now.setLocale(locale)
  } else if (state.i18n) {
    return state.now.setLocale(state.i18n.locale)
  }

  return state.now
}

export interface Getters {
  token: string | null
  isInstalled: boolean
  currentDate: (
    format?: DateTimeFormatOptions & LocaleOptions,
    locale?: Locale
  ) => string
  currentTime: (locale?: Locale) => string
}

const getters: GetterTree<State, State> = {
  token: state => state.device && state.device.token,
  isInstalled: (_, { token }: Getters) => !!token,
  currentDate: state =>
    ((format, locale) => {
      const date = getLocalizedNow(state, locale)

      return date ? date.toLocaleString(format || defaultDateFormat) : ''
    }) as Getters['currentDate'],
  currentTime: state =>
    (locale => {
      const date = getLocalizedNow(state, locale)

      return date
        ? date.toLocaleString(
            DateTime.TIME_SIMPLE as DateTimeFormatOptions & LocaleOptions
          )
        : ''
    }) as Getters['currentTime']
}

export default getters
