import { Debugger } from 'debug'

import debug from './debug'

const logRequest: <R = any>(
  request: () => Promise<R>,
  namespace?: string | Debugger
) => Promise<R | null> = async (request, namespace) => {
  try {
    return await request()
  } catch (error) {
    const message = 'Failed to fetch data: %O'

    if (!namespace) {
      debug(message, error)
    } else if (typeof namespace === 'string') {
      debug.extend(namespace)(message, error)
    } else {
      namespace(message, error)
    }

    return null
  }
}

export default logRequest
