const extractDataUrl: (url: string) => Promise<string> = url => {
  return new Promise((resolve, reject) => {
    if (window && document && /^(?:https?:)?\/\//i.test(url)) {
      const image = new Image()
      image.crossOrigin = 'anonymous'

      const canvas = document.createElement('canvas')
      canvas.width = 300
      canvas.height = 300

      const context = canvas.getContext('2d')

      if (!context) {
        reject(new Error('Failed to load context'))
        return
      }

      image.onload = () => {
        context.drawImage(image, 0, 0)

        resolve(canvas.toDataURL('image/jpeg'))
      }

      image.src = url
    } else {
      resolve(url)
    }
  })
}

export default extractDataUrl
