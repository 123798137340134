import db from '~/assets/services/ImageStore'
import extractDataUrl from '~/assets/helpers/extractDataUrl'

export const persistImage = async (id: string, data?: string | null) => {
  if (!data || data === id) return data || null

  const image =
    process.env.IMAGE_DOWNLOAD === 'on' ? await extractDataUrl(data) : data

  await db.images.put({
    id,
    image
  })

  return id
}

export const restoreImage = async (id?: string | null) => {
  if (!id) return null

  const entry = await db.images.get(id)

  return entry ? entry.image : id
}

export const removeImage = async (id: string) => {
  if (!id) return null

  const entry = await db.images.get(id)

  if (entry) {
    await db.images.delete(id)
  }

  return entry || null
}

export const resetImages = async () => {
  const images = await db.images.toArray()

  await images.reduce(async (p, i) => {
    await p

    await db.images.delete(i.id)
  }, Promise.resolve())
}
