import { SET_CLOCK, UPDATE_NOW, SET_DEVICE, LOCK_ADMIN } from './mutations'
import {
  AUTHORIZE_USER,
  CLEAR_AUTHORIZATION,
  UPDATE_USER,
  UPDATE_COURSE,
  COMPLETE_COURSE_DATE,
  UPDATE_LICENSE_HOLDER
} from './backend/mutations'

export const mutationTypes = {
  SET_CLOCK,
  UPDATE_NOW,
  SET_DEVICE,
  LOCK_ADMIN,
  AUTHORIZE_USER: `backend/${AUTHORIZE_USER}`,
  CLEAR_AUTHORIZATION: `backend/${CLEAR_AUTHORIZATION}`,
  UPDATE_USER: `backend/${UPDATE_USER}`,
  UPDATE_COURSE: `backend/${UPDATE_COURSE}`,
  COMPLETE_COURSE_DATE: `backend/${COMPLETE_COURSE_DATE}`,
  UPDATE_LICENSE_HOLDER: `backend/${UPDATE_LICENSE_HOLDER}`
}

export { default as state } from './state'
export { default as getters } from './getters'
export { default as actions } from './actions'
export { default as mutations } from './mutations'
