export const STANDALONE_HASH = ':standalone:'

export default () => {
  const hash = window.location.hash

  if (hash === '#' + STANDALONE_HASH) {
    history.replaceState(history.state, '', '/')
    sessionStorage.setItem(STANDALONE_HASH, 'yes')
    return true
  } else if (sessionStorage.getItem(STANDALONE_HASH) === 'yes') {
    return true
  }

  if (matchMedia('(display-mode)').matches) {
    return matchMedia('(display-mode: standalone)').matches
  }

  return false
}
