import { Plugin } from '@nuxt/types'

import localforage from 'localforage'
import VuexPersistence from 'vuex-persist'

import { State } from '~/store/state'

import { debug as baseDebug } from '~/assets/helpers'
import {
  UPDATE_NOW,
  SET_CLOCK,
  LOCK_ADMIN,
  SET_PROGRESS_STATE,
  SET_PROGRESS_VALUE,
  SET_PROGRESS_STEP,
  INCREMENT_PROGRESS,
  RESET_PROGRESS,
  BATTERY_LEVEL,
  BATTERY_COUNTDOWN
} from '~/store/mutations'

const debug = baseDebug.extend('persistence')

const badMutations = [
  UPDATE_NOW,
  SET_CLOCK,
  LOCK_ADMIN,
  SET_PROGRESS_STATE,
  SET_PROGRESS_VALUE,
  SET_PROGRESS_STEP,
  INCREMENT_PROGRESS,
  RESET_PROGRESS,
  BATTERY_LEVEL,
  BATTERY_COUNTDOWN
]

const persistence: Plugin = async ({ store }) => {
  if (navigator.storage && navigator.storage.persist) {
    const persistent = await navigator.storage.persisted()

    if (persistent || (await navigator.storage.persist())) {
      debug('storage protection enabled')
    } else {
      debug('storage protection disabled')
    }
  } else {
    debug('missing api to request storage protection')
  }

  new VuexPersistence({
    key: 'niederstaetter',
    storage: localforage,
    asyncStorage: true,
    reducer: ({ backend, i18n, device }: State) => ({
      backend,
      i18n,
      device
    }),
    filter: mutation => !badMutations.includes(mutation.type)
  }).plugin(store)

  // @ts-ignore
  await store.restored
}

export default persistence
