const middleware = {}

middleware['auth'] = require('../middleware/auth.ts')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['guest'] = require('../middleware/guest.ts')
middleware['guest'] = middleware['guest'].default || middleware['guest']

middleware['installed'] = require('../middleware/installed.ts')
middleware['installed'] = middleware['installed'].default || middleware['installed']

middleware['lock'] = require('../middleware/lock.ts')
middleware['lock'] = middleware['lock'].default || middleware['lock']

middleware['unlocked'] = require('../middleware/unlocked.ts')
middleware['unlocked'] = middleware['unlocked'].default || middleware['unlocked']

export default middleware
