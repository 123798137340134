import {
  AUTHORIZE_USER,
  CLEAR_AUTHORIZATION,
  UPDATE_COURSE,
  UPDATE_USER,
  UPDATE_LICENSE_HOLDER
} from './mutations'

export const mutationTypes = {
  AUTHORIZE_USER,
  CLEAR_AUTHORIZATION,
  UPDATE_USER,
  UPDATE_COURSE,
  UPDATE_LICENSE_HOLDER
}

export { default as state } from './state'
export { default as getters } from './getters'
export { default as actions } from './actions'
export { default as mutations } from './mutations'
