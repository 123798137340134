import { ActionTree } from 'vuex'

import {
  RESET_PROGRESS,
  INCREMENT_PROGRESS,
  SET_PROGRESS_STATE,
  SET_PROGRESS_STEP,
  SET_PROGRESS_VALUE
} from '../mutations'

import { State as RootState } from '../state'
import { State } from './state'
import { Getters } from './getters'
import {
  AUTHORIZE_USER,
  UPDATE_USER,
  REMOVE_USER,
  UPDATE_COURSE,
  COMPLETE_COURSE_DATE,
  UPDATE_LICENSE_HOLDER,
  REMOVE_LICENSE_HOLDERS,
  CLEAR_AUTHORIZATION,
  SET_PRINTERS,
  SET_LOCATIONS,
  RESET_STATE,
  REMOVE_COURSE
} from './mutations'

import { User, Course, CourseDate, Settings } from '~/assets/models'
import { Signatory } from '~/assets/models/user'
import { RemoteCourse } from '~/assets/models/course'
import { DateLicenseHolder } from '~/assets/models/courseDate'

import LicenseHolder, {
  RemoteLicenseHolder
} from '~/assets/models/licenseHolder'

import {
  debug as baseDebugger,
  logRequest,
  needsResync,
  persistImage,
  restoreImage,
  removeImage,
  resetImages
} from '~/assets/helpers'

const fullSync = process.env.FULL_CONTEXT_SYNC === 'on'

const debug = baseDebugger.extend('backend')
const debugCourses = debug.extend('courses')
const debugCourseDates = debug.extend('courseDates')
const debugSettings = debug.extend('settings')
const debugLicenseHolders = debug.extend('holders')

export interface Actions {
  login: (code: string) => Promise<User | undefined>
  logout: () => Promise<boolean>
  reset: () => Promise<void>
  syncSettings: () => Promise<User[]>
  syncLicenseHolders: (ids: number[]) => Promise<LicenseHolder[]>
  syncCourse: (course: Course | RemoteCourse) => Promise<Course | RemoteCourse>
  purgeCourse: (course: RemoteCourse | Course) => Promise<void>
  syncCourseDate: (payload: {
    courseId: number
    date: CourseDate
  }) => Promise<CourseDate>
  completeCourseDate: (payload: {
    courseId: number
    date: CourseDate
  }) => Promise<CourseDate>
  updateHolder: (payload: {
    courseId: number
    dateId: number
    holder: DateLicenseHolder
  }) => Promise<DateLicenseHolder>
  purgeLicenseHolders: (ids: number[]) => Promise<void>
}

const actions: ActionTree<State, RootState> = {
  login({ commit, getters }, code: number): User | undefined {
    const get = getters as Getters
    debug('attempt local authorization')
    commit(AUTHORIZE_USER, code)

    if (get.user) {
      this.$axios.setHeader('X-NS-USER-ID', `${get.user.id}`)
    }

    return get.user
  },

  logout({ commit, getters }): boolean {
    const get = getters as Getters

    if (get.user) {
      debug('logout user %d', get.user.name)

      commit(CLEAR_AUTHORIZATION)
      this.$axios.setHeader('X-NS-USER-ID', false)

      return true
    }

    return false
  },

  async reset({ commit, dispatch }): Promise<void> {
    commit(RESET_STATE)

    await resetImages()

    await dispatch('syncSettings')
  },

  async syncSettings({ state, commit, dispatch }): Promise<User[]> {
    debugSettings('fetching remote entries...')
    const settings = await logRequest<Settings>(
      () => this.$axios.$get('settings'),
      debugSettings.extend('sync')
    )

    if (settings) {
      const debugSettingLeaders = debugSettings.extend('leaders')

      const leaders = settings.course_leaders
      debugSettingLeaders('fetched %d entries, updating...', leaders.length)
      for (const user of leaders) {
        commit(UPDATE_USER, {
          ...user,
          signature: await persistImage(`user:${user.id}`, user.signature)
        })
      }

      const removable = state.users.filter(
        local => !leaders.some(remote => remote.id === local.id)
      )

      debugSettingLeaders('removing %d entries...', removable.length)
      for (const user of removable) {
        commit(REMOVE_USER, user)
      }

      if (
        state.authorized &&
        !state.users.some(u => u.id === state.authorized)
      ) {
        debugSettingLeaders('authorized user has been removed, logging out...')

        await dispatch('logout')
      }

      const debugSettingPrinters = debugSettings.extend('printers')

      const printers = settings.printers
      debugSettingPrinters('fetched %d entries, updating...', printers.length)

      commit(SET_PRINTERS, printers)

      const debugSettingLocations = debugSettings.extend('locations')

      const locations = settings.course_locations
      debugSettingLocations('fetched %d entries, updating...', locations.length)

      commit(SET_LOCATIONS, locations)
    }

    debugSettings('returning local entries')

    return state.users
  },

  async syncLicenseHolders(
    { state, commit, getters },
    ids: number[]
  ): Promise<LicenseHolder[]> {
    const get = getters as Getters
    if (!state.authorized) {
      throw new Error('Unauthorized')
    }

    commit(SET_PROGRESS_STATE, 'syncLicenseHolders', { root: true })
    commit(SET_PROGRESS_VALUE, 0, { root: true })
    commit(SET_PROGRESS_STEP, 100 / ids.length, { root: true })

    debugLicenseHolders('synching %d entries...', ids.length)

    await ids.reduce(async (p, id) => {
      await p

      debugLicenseHolders('syncing id: %d...', id)
      const local = get.licenseHolder(id)

      if (state.resyncThrottle && !needsResync(local)) {
        debugLicenseHolders('no sync needed, skipping...')

        commit(INCREMENT_PROGRESS, null, { root: true })

        return
      }

      debugLicenseHolders('fetching remote entry...')
      const remote = await logRequest<RemoteLicenseHolder | null>(async () => {
        return local && (!local._syncedAt || local._syncedAt < local._updatedAt)
          ? this.$axios.$post(`license-holders/${local.id}`, {
              ...local,
              image: await restoreImage(local.image)
            })
          : this.$axios.$get(`license-holders/${id}`)
      }, debugLicenseHolders.extend('fetch'))

      if (!remote) {
        debugLicenseHolders('fetch failed...')
        if (!local) {
          debugLicenseHolders('no local entry present, skipping...')

          commit(INCREMENT_PROGRESS, null, { root: true })

          return
        }

        debugLicenseHolders('updating local entry with sync error...')
        const payload: LicenseHolder = {
          ...local,
          _error: true,
          _updatedAt: this.$dateTime.local().toMillis()
        }

        commit(UPDATE_LICENSE_HOLDER, payload)
        commit(INCREMENT_PROGRESS, null, { root: true })

        return
      }

      debugLicenseHolders('fetch id=%d succeeded, updating local entry...', id)

      remote.image =
        (await persistImage(`holder:${remote.id}:image`, remote.image)) ||
        (local && local.image) ||
        null

      const timestamp = this.$dateTime.local().toMillis()
      const payload: LicenseHolder = {
        ...local,
        ...remote,
        _error: false,
        _syncedAt: timestamp,
        _updatedAt: timestamp,
        _signedAt: null,
        _signedLeaveAt: null,
        signature: null,
        signature_leave: null
      }

      commit(UPDATE_LICENSE_HOLDER, payload)
      commit(INCREMENT_PROGRESS, null, { root: true })
    }, Promise.resolve())

    debugLicenseHolders('returning local entries')

    commit(RESET_PROGRESS, null, { root: true })

    return ids.reduce((localHolders, id) => {
      const local = get.licenseHolder(id)

      if (local) {
        localHolders.push(local)
      }

      return localHolders
    }, [] as LicenseHolder[])
  },

  async syncCourse(
    { state, dispatch, commit, getters },
    course: Course | RemoteCourse
  ): Promise<Course | RemoteCourse> {
    const get = getters as Getters
    if (!state.authorized) {
      throw new Error('Unauthorized')
    }

    if (state.completeCourses.includes(course.id)) {
      debug('not synching complete course')

      return course
    }

    debugCourses('syncing entry: %d...', course.id)

    if (state.resyncThrottle && !needsResync(course)) {
      debugCourses('no sync needed, returning untouched')

      return course
    }

    debugCourses('attempting remote update...')
    commit(SET_PROGRESS_STATE, 'updateCourseData', { root: true })
    commit(SET_PROGRESS_VALUE, 0, { root: true })

    const remote =
      (await logRequest<RemoteCourse>(
        () => this.$axios.$get(`courses/${course.id}`),
        'sync:course'
      )) || course

    if (state.completeCourses.includes(remote.id)) {
      debugCourses('entry already completed, returning update result')

      commit(RESET_PROGRESS, null, { root: true })

      return remote
    }

    debugCourses('getting local entry...')
    let local = get.course(course.id)

    if (!local) {
      debugCourses('local entry missing, creating new...')

      const timestamp = this.$dateTime.local().toMillis()
      local = {
        ...remote,
        course_dates: [],
        _syncedAt: timestamp,
        _updatedAt: timestamp,
        _syncErrors: []
      }

      commit(UPDATE_COURSE, local)
    }

    const { course_dates: remoteDates, ...rest } = remote

    const incompleteDates = remoteDates.filter(
      cd => !state.completeDates.includes(cd.id) && !cd.confirmed_at
    )

    if (!incompleteDates.length) {
      debugCourses('course has no incomplete dates, deploying autofix...')

      await dispatch('purgeCourse', remote)

      debugCourses('course fixed, returning remote result')

      commit(RESET_PROGRESS, null, { root: true })

      return remote
    }

    debugCourses('syncing course license holders...')
    const holderIds = rest.participants.map(p => p.id)
    await dispatch('syncLicenseHolders', holderIds)

    commit(SET_PROGRESS_STEP, 100 / incompleteDates.length, { root: true })
    commit(SET_PROGRESS_VALUE, 0, { root: true })

    debugCourses('syncing course dates...')
    const syncedDates = await incompleteDates.reduce(async (p, remoteDate) => {
      const result = await p

      const timestamp = this.$dateTime.local().toMillis()
      const payload: CourseDate = {
        license_holders: [],
        _syncedAt: timestamp,
        _updatedAt: timestamp,
        _confirmedAt: null,
        signatories: [],
        _syncErrors: {},
        ...(local && local.course_dates.find(cd => cd.id === remoteDate.id)),
        ...remoteDate
      }
      debugCourses(payload)

      const date = await dispatch('syncCourseDate', {
        courseId: rest.id,
        date: payload
      })

      if (date) {
        result.push(date)
      }

      return result
    }, Promise.resolve([] as CourseDate[]))

    if (state.completeCourses.includes(remote.id)) {
      debugCourses('entry completed, returning sync result')

      const syncResult: Course = {
        ...local,
        ...rest,
        course_dates: syncedDates,
        _syncErrors: []
      }

      commit(RESET_PROGRESS, null, { root: true })

      return syncResult
    }

    debugCourses('updating local entry...')
    local = get.course(local.id)!
    const timestamp = this.$dateTime.local().toMillis()
    const payload: Course = {
      ...local,
      ...rest,
      course_dates: syncedDates.filter(
        cd => !state.completeDates.includes(cd.id) && !cd.confirmed_at
      ),
      _updatedAt: timestamp,
      _syncedAt: local._syncErrors.length ? local._syncedAt : timestamp
    }
    commit(UPDATE_COURSE, payload)

    debugCourses('returning local entry')

    commit(RESET_PROGRESS, null, { root: true })

    return get.course(local.id)!
  },

  async syncCourseDate(
    { state, dispatch, commit, getters, rootState },
    { courseId, date }: { courseId: number; date: CourseDate }
  ): Promise<CourseDate> {
    if (!state.authorized) {
      throw new Error('Unauthorized')
    }
    const get = getters as Getters

    commit(SET_PROGRESS_STATE, 'syncCourseDates', { root: true })

    debugCourseDates('getting local parent entry...')
    const course = get.course(courseId)

    if (!course) {
      debugCourseDates('local parent entry missing, returning untouched')

      if (rootState.progress.step) {
        commit(INCREMENT_PROGRESS, null, { root: true })

        if (rootState.progress.value >= 100) {
          commit(RESET_PROGRESS, null, { root: true })
        }
      }

      return date
    }

    if (
      state.completeDates.includes(date.id) ||
      !!date.confirmed_at ||
      (state.resyncThrottle && !needsResync(date))
    ) {
      debugCourseDates('no sync needed, returning untouched')

      if (rootState.progress.step) {
        commit(INCREMENT_PROGRESS, null, { root: true })

        if (rootState.progress.value >= 100) {
          commit(RESET_PROGRESS, null, { root: true })
        }
      }

      return date
    }

    const _syncErrors: CourseDate['_syncErrors'] = {}
    // eslint-disable-next-line camelcase
    const { license_holders, signatories, ...rest } = date

    const progressStep = rootState.progress.step || 100
    const totalLength = license_holders.length + signatories.length
    if (totalLength) {
      commit(SET_PROGRESS_STEP, progressStep / totalLength, { root: true })
    } else {
      commit(INCREMENT_PROGRESS, null, { root: true })
    }

    const debugCourseDateHolders = debugCourseDates.extend('holders')
    const updatedHolders = await license_holders.reduce(async (p, holder) => {
      const result = await p

      debugCourseDateHolders('syncing id: %d...', holder.id)

      if ((!fullSync || state.resyncThrottle) && !needsResync(holder)) {
        debugCourseDateHolders('no sync needed, returning untouched')

        commit(INCREMENT_PROGRESS, null, { root: true })

        return [...result, holder]
      }

      // @TODO: Implement RemoteDateLicenseHolder
      debugCourseDateHolders('fetching remote entry...')
      const syncResult = await logRequest<DateLicenseHolder>(
        async () =>
          this.$axios.$post(
            `course-dates/${date.id}/license-holders/${holder.id}`,
            {
              ...holder,
              signature: await restoreImage(holder.signature),
              signature_leave: await restoreImage(holder.signature_leave)
            }
          ),
        debugCourseDateHolders.extend('sync')
      )

      if (!syncResult) {
        debugCourseDateHolders('sync failed, returning untouched')

        _syncErrors.members = _syncErrors.members || []
        _syncErrors.members.push(holder.id)

        commit(INCREMENT_PROGRESS, null, { root: true })

        return [...result, holder]
      }

      if (fullSync && syncResult) {
        syncResult.signature = await persistImage(
          `holder:${syncResult.id}:${rest.id}:signature`,
          syncResult.signature
        )

        syncResult.signature_leave = await persistImage(
          `holder:${syncResult.id}:${rest.id}:signature_leave`,
          syncResult.signature_leave
        )
      }

      debugCourseDateHolders('sync succeeded, returning updated entry')
      const timestamp = this.$dateTime.local().toMillis()
      const payload: DateLicenseHolder = {
        ...holder,
        ...((fullSync && syncResult) || {}),
        _updatedAt: timestamp,
        _syncedAt: timestamp
      }

      commit(INCREMENT_PROGRESS, null, { root: true })

      return [...result, payload]
    }, Promise.resolve([] as DateLicenseHolder[]))

    const debugCourseDateSignatories = debugCourseDates.extend('signatories')
    const updatedSignatories = !signatories
      ? []
      : await signatories.reduce(async (p, signatory) => {
          const result = await p

          debugCourseDateSignatories('syncing id: %d...', signatory.id)

          if ((!fullSync || state.resyncThrottle) && !needsResync(signatory)) {
            debugCourseDateSignatories('no sync needed, returning untouched')

            commit(INCREMENT_PROGRESS, null, { root: true })

            return [...result, signatory]
          }

          debugCourseDateSignatories('fetching remote entry...')
          const syncResult = await logRequest<Signatory>(
            async () =>
              this.$axios.$post(
                `course-dates/${date.id}/course-leaders/${signatory.id}`,
                {
                  ...signatory,
                  signature: await restoreImage(signatory.signature)
                }
              ),
            debugCourseDateSignatories.extend('sync')
          )

          if (!syncResult) {
            debugCourseDateSignatories('sync failed, returning untouched')
            _syncErrors.signatories = _syncErrors.signatories || []
            _syncErrors.signatories.push(signatory.id)

            commit(INCREMENT_PROGRESS, null, { root: true })

            return [...result, signatory]
          }

          debugCourseDateSignatories('sync succeeded, returning updated entry')
          const timestamp = this.$dateTime.local().toMillis()
          const payload: Signatory = {
            ...signatory,
            signature:
              (fullSync &&
                (await persistImage(
                  `user:${syncResult.id}`,
                  syncResult.signature
                ))) ||
              signatory.signature,
            _updatedAt: timestamp,
            _syncedAt: timestamp
          }

          commit(INCREMENT_PROGRESS, null, { root: true })

          return [...result, payload]
        }, Promise.resolve([] as Signatory[]))

    const syncSuccess = !_syncErrors.members && !_syncErrors.signatories
    const timestamp = this.$dateTime.local().toMillis()
    const other = course.course_dates.filter(cd => cd.id !== rest.id)

    commit(SET_PROGRESS_STEP, progressStep, { root: true })

    if (
      syncSuccess &&
      rest._confirmedAt &&
      !state.completeDates.includes(rest.id)
    ) {
      debugCourseDates('entry confirmed, attempting completion...')

      if (
        await logRequest(() =>
          this.$axios.$post(`course-dates/${rest.id}/confirm`, {
            confirmedAt: rest._confirmedAt
          })
        )
      ) {
        debugCourseDates('completion succeeded, purgin data...')
        await dispatch('completeCourseDate', { courseId, date })

        debugCourseDates('returning sync result')

        if (rootState.progress.value >= 100) {
          commit(RESET_PROGRESS, null, { root: true })
        }

        return {
          ...rest,
          license_holders: updatedHolders,
          signatories: updatedSignatories || [],
          _updatedAt: timestamp,
          _syncedAt: timestamp,
          _syncErrors
        }
      } else {
        debugCourseDates('completion failed, continuing...')

        _syncErrors.confirmation = true
      }
    }

    debugCourseDates('updating local entry...')
    const payload: Course = {
      ...course,
      course_dates: [
        ...other,
        {
          ...rest,
          license_holders: updatedHolders,
          signatories: updatedSignatories || [],
          _updatedAt: timestamp,
          _syncedAt:
            syncSuccess && !_syncErrors.confirmation
              ? timestamp
              : rest._syncedAt,
          _syncErrors
        }
      ],
      _updatedAt: timestamp,
      _syncErrors: [
        ...course._syncErrors.filter(e => e !== rest.id),
        ...(syncSuccess && !_syncErrors.confirmation ? [] : [rest.id])
      ]
    }

    commit(UPDATE_COURSE, payload)

    if (rootState.progress.value >= 100) {
      commit(RESET_PROGRESS, null, { root: true })
    }

    debugCourseDates('returning local entry')

    return get.courseDate(courseId, date.id) || date
  },

  async completeCourseDate(
    { state, commit, dispatch, getters },
    { courseId, date }: { courseId: number; date: CourseDate }
  ): Promise<CourseDate> {
    const get = getters as Getters
    if (!state.authorized || !get.user) {
      throw new Error('Unauthorized')
    }

    const course = get.course(courseId)
    if (!course || !course.course_dates.some(cd => cd.id === date.id)) {
      throw new Error('Invalid course id')
    }

    await Promise.all(
      date.license_holders.map(async lh => {
        await removeImage(`holder:${lh.id}:${date.id}:signature`)
        await removeImage(`holder:${lh.id}:${date.id}:signature_leave`)
      })
    )

    await Promise.all(
      date.signatories
        .filter(s => !s._persistSignature)
        .map(async s => {
          if (!state.users.some(u => u.signature === `user:${s.id}`)) {
            await removeImage(`user:${s.id}`)
          }
        })
    )

    const participantIDs = course.participants.map(p => p.id)

    commit(COMPLETE_COURSE_DATE, { courseId, date })
    await dispatch('purgeLicenseHolders', participantIDs)

    if (!get.course(courseId)) {
      const user: User = {
        ...get.user,
        _courses: get.user._courses.filter(c => c !== courseId)
      }

      commit(UPDATE_USER, user)
    }

    return date
  },

  async purgeCourse(
    { state, dispatch, commit, getters },
    course: RemoteCourse | Course
  ): Promise<void> {
    const get = getters as Getters
    if (!state.authorized || !get.user) {
      throw new Error('Unauthorized')
    }

    const dates = get.courseDates(course.id)

    await dates.reduce(async (p, d) => {
      await p

      await Promise.all(
        d.license_holders.map(async lh => {
          await removeImage(`holder:${lh.id}:${d.id}:signature`)
          await removeImage(`holder:${lh.id}:${d.id}:signature_leave`)
        })
      )

      await Promise.all(
        d.signatories
          .filter(s => !s._persistSignature)
          .map(async s => {
            if (!state.users.some(u => u.signature === `user:${s.id}`)) {
              await removeImage(`user:${s.id}`)
            }
          })
      )
    }, Promise.resolve())

    if (get.course(course.id)) {
      commit(REMOVE_COURSE, course)
    }

    await dispatch(
      'purgeLicenseHolders',
      course.participants.map(p => p.id)
    )
  },

  async updateHolder(
    { state: { authorized }, commit, getters },
    {
      courseId,
      dateId,
      holder
    }: {
      courseId: number
      dateId: number
      holder: DateLicenseHolder
    }
  ): Promise<DateLicenseHolder> {
    if (!authorized) {
      throw new Error('Unauthorized')
    }
    const get = getters as Getters

    const course = get.course(courseId)
    const date = get.courseDate(courseId, dateId)
    if (!course || !date) {
      return holder
    }

    const local = date.license_holders.find(lh => lh.id === holder.id)
    const otherHolders = date.license_holders.filter(lh => lh.id !== holder.id)
    const otherDates = course.course_dates.filter(cd => cd.id !== date.id)

    const dateLicenseHolder: DateLicenseHolder = (({
      /* eslint-disable camelcase */
      id,
      signature,
      signature_leave,
      enter_at,
      exit_at,
      _syncedAt,
      _updatedAt,
      _signedAt,
      _signedLeaveAt
      /* eslint-enable camelcase */
    }) => ({
      id,
      signature,
      signature_leave,
      enter_at,
      exit_at,
      _updatedAt,
      _syncedAt,
      _signedAt,
      _signedLeaveAt
    }))(holder)

    if (dateLicenseHolder.signature) {
      dateLicenseHolder.signature = await persistImage(
        `holder:${dateLicenseHolder.id}:${date.id}:signature`,
        dateLicenseHolder.signature
      )
    }

    if (dateLicenseHolder.signature_leave) {
      dateLicenseHolder.signature_leave = await persistImage(
        `holder:${dateLicenseHolder.id}:${date.id}:signature_leave`,
        dateLicenseHolder.signature_leave
      )
    }

    const timestamp = this.$dateTime.local().toMillis()
    const payload: Course = {
      ...course,
      course_dates: [
        ...otherDates,
        {
          ...date,
          license_holders: [
            ...otherHolders,
            {
              ...local,
              ...dateLicenseHolder,
              _updatedAt: timestamp
            }
          ],
          _updatedAt: timestamp
        }
      ],
      _updatedAt: timestamp
    }
    commit(UPDATE_COURSE, payload)

    return dateLicenseHolder
  },

  async purgeLicenseHolders({ state, commit }, ids: number[]): Promise<void> {
    const removableHolderIDs = await ids.reduce(async (promise, id) => {
      const removable = await promise
      const local = state.licenseHolders.find(holder => holder.id === id)

      if (
        local &&
        (!local._updatedAt || local._syncedAt >= local._updatedAt) &&
        !state.courses.some(c => c.participants.some(p => p.id === id))
      ) {
        removable.push(id)
      }

      return removable
    }, Promise.resolve([]) as Promise<number[]>)

    await Promise.all(
      removableHolderIDs.map(id => removeImage(`holder:${id}:image`))
    )

    commit(REMOVE_LICENSE_HOLDERS, removableHolderIDs)
  }
}

export default actions
