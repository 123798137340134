import { GetterTree } from 'vuex'
import { SHA256 } from 'crypto-js'

import { State as RootState } from '../state'
import { State } from './state'

import { Course, User, LicenseHolder, CourseDate } from '~/assets/models'

export interface Getters {
  user?: User
  users: User[]
  courseLeader: (id: number) => User | undefined
  confirmCode: (code: string) => boolean

  failedCourses: Course[]
  course: (id: number) => Course | undefined

  courseDates: (courseId: number) => CourseDate[]
  courseDate: (courseId: number, id: number) => CourseDate | undefined

  failedLicenseHolders: LicenseHolder[]
  licenseHolder: (id: number) => LicenseHolder | undefined
}

const getters: GetterTree<State, RootState> = {
  user: ({ users, authorized }) => users.find(u => u.id === authorized),

  users: ({ authorized, users }) =>
    authorized ? users.filter(s => s.id !== authorized) : [],

  courseLeader: ({ authorized, users }) => (id: number) =>
    authorized ? users.find(u => u.id === id) : undefined,

  confirmCode: (_, { user }: Getters) => (code: string) =>
    user && user.pin === SHA256(code).toString(),

  failedCourses: ({ courses }) =>
    courses
      .filter(c => !!c._syncErrors.length)
      .map(c => ({
        ...c,
        course_dates: c.course_dates.filter(cd => c._syncErrors.includes(cd.id))
      })),
  course: ({ courses }) => (id: number) => courses.find(c => c.id === id),

  courseDates: (_, { course }: Getters) => (courseId: number) => {
    const parent = course(courseId)

    return parent ? parent.course_dates : []
  },
  courseDate: (_, { courseDates }: Getters) => (courseId: number, id: number) =>
    courseDates(courseId).find(cd => cd.id === id),

  licenseHolder: ({ licenseHolders }) => (id: number) =>
    licenseHolders.find(lh => lh.id === id),

  failedLicenseHolders: ({ courses, licenseHolders }) =>
    licenseHolders
      .filter(
        lh =>
          lh._error ||
          !courses.some(c => c.participants.some(p => p.id === lh.id))
      )
      .sort((a, b) => (a.tax_id > b.tax_id ? 1 : a.tax_id < b.tax_id ? -1 : 0))
}

export default getters
