import { Component, Vue } from 'nuxt-property-decorator'

@Component
export default class AwaitsRefs extends Vue {
  public awaitRef<R extends Vue = Vue>(name: string): Promise<R> {
    return new Promise((resolve, reject) => {
      let retries = 0
      const waitForRef = setInterval(() => {
        if (this.$refs[name]) {
          clearInterval(waitForRef)
          resolve(this.$refs[name] as R)
        } else if (retries < 10) {
          retries++
        } else {
          clearInterval(waitForRef)
          reject(new Error(`Unable to initialize ${name}`))
        }
      }, 100)
    })
  }
}
