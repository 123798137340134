import { Middleware } from '@nuxt/types'

import { State } from '~/store/state'
import { LOCK_ADMIN } from '~/store/mutations'

const auth: Middleware = ({ store }) => {
  const state: State = store.state

  if (!state.locked) {
    store.commit(LOCK_ADMIN, true)
  }
}

export default auth
