





















import { Component, Emit, Model, Vue } from 'nuxt-property-decorator'

import { Alert } from '@/assets/mixins/DisplaysAlerts'

@Component
export default class AlertContainer extends Vue {
  @Model('change', {
    type: Array,
    default: () => []
  })
  readonly alerts!: Alert[]

  counters: {
    [id: number]: number
  } = []

  @Emit('change')
  onUpdate(id: number, show: boolean) {
    if (show) {
      return this.alerts
    }

    return this.alerts.filter(alert => alert.id !== id)
  }
}
