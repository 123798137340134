import { User, Course, LicenseHolder, Printer, Location } from '~/assets/models'

export interface CourseDateReference {
  course: number
  date: number
  title: string
}

export interface State {
  authorized: number | null
  users: User[]
  printers: Printer[]
  defaultPrinter: {
    paper: number | null
    card: number | null
  }
  locations: Location[]
  selectedLocation: number | null
  courses: Course[]
  licenseHolders: LicenseHolder[]
  completeCourses: number[]
  completeDates: number[]
  activeDates: CourseDateReference[]
  resyncThrottle: boolean
}

const state: () => State = () => ({
  authorized: null,
  users: [],
  printers: [],
  defaultPrinter: {
    paper: null,
    card: null
  },
  locations: [],
  selectedLocation: null,
  courses: [],
  licenseHolders: [],
  completeCourses: [],
  completeDates: [],
  activeDates: [],
  resyncThrottle: true
})

export default state
