import { DateTime } from 'luxon'
import { State as BackendState } from './backend/state'

export interface State {
  now: DateTime | null
  clock: NodeJS.Timeout | null
  battery: {
    check: number
    level: number
  }
  locked: boolean

  device: {
    name: string
    token: string
  } | null

  progress: {
    state: string | null
    value: number
    step: number
  }

  backend?: BackendState
  i18n?: {
    locale: string
  }
}

const state: () => State = () => ({
  now: null,
  clock: null,
  battery: {
    check: 0,
    level: 100
  },
  locked: false,

  device: null,

  progress: {
    state: null,
    value: 0,
    step: 0
  }
})

export default state
