import Dexie from 'dexie'

export interface Image {
  id: string
  image: string
}

export class ImageStore extends Dexie {
  images: Dexie.Table<Image, string>

  constructor() {
    super('niederstaetter-image-store')

    this.version(1).stores({
      images: 'id'
    })

    this.images = this.table('images')
  }
}

export default new ImageStore()
