import Fingerprint from 'fingerprintjs2'
import { UAParser } from 'ua-parser-js'

export interface Metadata {
  timezone: string
  platform: string
  screenResolution: string
  userAgent: IUAParser.IResult
}

export const createFingerprint = async () => {
  const components = await Fingerprint.getPromise()

  const id = Fingerprint.x64hash128(
    components.map(component => component.value).join(),
    31
  )
  const metadata = components.reduce((result, component) => {
    switch (component.key) {
      case 'timezone':
      case 'platform':
        result[component.key] = component.value
        break
      case 'screenResolution':
        result[component.key] = `${component.value[0]}x${component.value[1]}`
        break
      case 'userAgent':
        const data = new UAParser(component.value)

        result[component.key] = data.getResult()
        break
    }

    return result
  }, {} as Metadata)

  return {
    id,
    metadata,
    timestamp: Date.now()
  }
}

export default (): Promise<{
  id: string
  metadata: Metadata
  timestamp: number
}> =>
  new Promise(resolve => {
    setTimeout(() => {
      resolve(createFingerprint())
    }, 500)
  })
