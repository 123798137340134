import { Plugin } from '@nuxt/types'
import { DateTime } from 'luxon'

declare module 'vue/types/vue' {
  interface Vue {
    $dateTime: typeof DateTime
  }
}

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $dateTime: typeof DateTime
  }
}

declare module 'vuex/types/index' {
  interface Store<S> {
    $dateTime: typeof DateTime
  }
}

const myPlugin: Plugin = (_, inject) => {
  inject('dateTime', DateTime)
}

export default myPlugin
