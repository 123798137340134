import { Component, Vue, Inject } from 'nuxt-property-decorator'

import { ShowAlertFn } from './DisplaysAlerts'

import { Course } from '~/assets/models'
import { RemoteCourse } from '~/assets/models/course'

@Component
export default class ChecksSyncStatus extends Vue {
  @Inject({ default: () => {} }) readonly showAlert!: ShowAlertFn

  protected checkSyncStatus(synced: Course | RemoteCourse) {
    if (
      !synced ||
      !('_syncErrors' in synced) ||
      !synced._syncErrors ||
      !synced._syncErrors.length
    ) {
      this.showAlert(`${this.$t('messages.synchronized')}`, 'success')

      return true
    }

    this.showAlert(`${this.$t('messages.syncFailed')}`, 'error')

    return false

    /** @TODO: implement better error reporting */

    // const partialFails = []
    // const { course, members, signatories } = synced._syncErrors

    // const failedHolders: LicenseHolder[] = this.$store.getters[
    //   'backend/failedLicenseHolders'
    // ].filter((lh: LicenseHolder) =>
    //   synced.members.some(m => m.license_holder_id === lh.id)
    // )

    // if (course) {
    //   partialFails.push(`${this.$t('messages.partialSyncFailCourse')}`)
    // }

    // if (members && members.length) {
    //   partialFails.push(
    //     `${this.$t('messages.partialSyncFailMembers', {
    //       errors: `<ul><li>${members.join('</li><li>')}</li></ul>`
    //     })}`
    //   )
    // }

    // if (signatories && signatories.length) {
    //   const names = this.$store.state.backend.users
    //     .filter((u: User) => signatories.includes(u.id))
    //     .map((u: User) => u.name)
    //   partialFails.push(
    //     `${this.$t('messages.partialSyncFailSignatories', {
    //       errors: `<ul><li>${names.join('</li><li>')}</li></ul>`
    //     })}`
    //   )
    // }

    // if (failedHolders.length) {
    //   partialFails.push(
    //     `${this.$t('messages.partialSyncFailLicenseHolders', {
    //       errors: `<ul><li>${failedHolders
    //         .map(lh => lh.tax_id)
    //         .join('</li><li>')}</li></ul>`
    //     })}`
    //   )
    // }

    // this.showAlert(
    //   `${this.$t('messages.partialSyncFail', {
    //     partials: `<ul><li>${partialFails.join('</li><li>')}</li></ul>`
    //   })}`,
    //   'warning',
    //   true
    // )

    // return false
  }
}
