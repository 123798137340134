import { Plugin } from '@nuxt/types'

import Vue from 'vue'
import {
  ValidationProvider,
  ValidationObserver,
  extend,
  configure
} from 'vee-validate'
import { required, min, numeric, email } from 'vee-validate/dist/rules'

import { debug as baseDebugger } from '~/assets/helpers'

extend('required', required)
extend('numeric', numeric)
extend('email', email)
extend('min', min)

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

const debug = baseDebugger.extend('validation')

const veeValidate: Plugin = ({ app }) => {
  configure({
    defaultMessage: (rawField, params) => {
      const field = app.i18n.te(`fields.${rawField}`)
        ? app.i18n.t(`fields.${rawField}`)
        : rawField

      const values = {
        ...(params || {}),
        field
      }

      debug('get validation message for parameters: %O', values)

      return (params &&
      params._rule_ &&
      app.i18n.te(`validation.${params._rule_}`)
        ? app.i18n.t(`validation.${params._rule_}`, values)
        : app.i18n.t('validation.generic', values)) as string
    }
  })
}

export default veeValidate
