import { MutationTree } from 'vuex'

import initState, { State } from './state'

export const UPDATE_NOW = 'updateNow'
export const SET_CLOCK = 'setClock'

export const LOCK_ADMIN = 'lockAdmin'

export const SET_PROGRESS_STATE = 'setProgressState'
export const SET_PROGRESS_VALUE = 'setProgressValue'
export const SET_PROGRESS_STEP = 'setProgressStep'
export const INCREMENT_PROGRESS = 'incrementProgress'
export const RESET_PROGRESS = 'resetProgress'

export const BATTERY_LEVEL = 'batteryLevel'
export const BATTERY_COUNTDOWN = 'batteryCountdown'

export const SET_DEVICE = 'setDevice'

export const RESET_STATE = 'resetState'

const batteryPollInterval = process.env.BATTERY_POLL_INTERVAL || '20'

const mutations: MutationTree<State> = {
  [UPDATE_NOW]: (state, newNow) => {
    state.now = newNow
  },
  [SET_CLOCK]: (state, timeout) => {
    state.clock = timeout
  },

  [BATTERY_LEVEL]: (state, level: number) => {
    state.battery.level = level
  },
  [BATTERY_COUNTDOWN]: state => {
    if (state.battery.check > +batteryPollInterval) {
      state.battery.check = 0
    } else {
      state.battery.check = state.battery.check + 1
    }
  },

  [SET_PROGRESS_STATE]: (state, pState: string) => {
    state.progress.state = pState
  },
  [SET_PROGRESS_VALUE]: (state, value: number) => {
    state.progress.value = value
  },
  [SET_PROGRESS_STEP]: (state, step: number) => {
    state.progress.step = step
  },
  [INCREMENT_PROGRESS]: state => {
    if (state.progress.step) {
      state.progress.value = state.progress.value + state.progress.step
    } else {
      state.progress.value++
    }
  },
  [RESET_PROGRESS]: state => {
    state.progress.state = null
    state.progress.value = 0
    state.progress.step = 0
  },

  [SET_DEVICE]: (state, device) => {
    state.device = device
  },

  [LOCK_ADMIN]: (state, lock?: boolean) => {
    if (typeof lock === 'boolean') {
      state.locked = lock
    } else {
      state.locked = !state.locked
    }
  },
  [RESET_STATE]: state => {
    const { locked } = initState()

    state.locked = locked
  }
}

export default mutations
