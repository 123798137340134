import { Middleware } from '@nuxt/types'

import { isApp } from '~/assets/helpers'

const auth: Middleware = ({ store, app, redirect, route }) => {
  const appCheck = isApp()
  const isInstalled =
    (process.env.PWA_CHECK === 'off' || appCheck) && store.getters.isInstalled

  if (!isInstalled && app.getRouteBaseName(route) !== 'setup') {
    redirect(app.localePath('setup'))
  } else if (isInstalled && app.getRouteBaseName(route) === 'setup') {
    redirect(app.localePath('index'))
  }
}

export default auth
