import { Middleware } from '@nuxt/types'
import { State } from '~/store/state'

const auth: Middleware = ({ store, app, redirect }) => {
  const state: State = store.state

  if (state.locked) {
    redirect(app.localePath('login'))
  }
}

export default auth
