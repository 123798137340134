import { Middleware } from '@nuxt/types'

import { State } from '~/store/state'

const guest: Middleware = ({ store, app, redirect }) => {
  const state: State = store.state

  if (!state.locked && state.backend && state.backend.authorized) {
    redirect(app.localePath('index'))
  }
}

export default guest
