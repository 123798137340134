import { DateTime } from 'luxon'

const resyncInterval = +(process.env.RESYNC_INTERVAL || 240) * 60 * 1000

export default (
  entity?:
    | {
        _syncedAt: number
        _updatedAt: number
      }
    | object
) => {
  return (
    !entity ||
    !('_syncedAt' in entity) ||
    !entity._syncedAt ||
    entity._syncedAt < entity._updatedAt ||
    entity._syncedAt <
      DateTime.local()
        .minus(resyncInterval)
        .toMillis()
  )
}
