import { Component, Provide, Vue } from 'nuxt-property-decorator'

export type AlertType = 'success' | 'info' | 'warning' | 'error'

export type ShowAlertFn = (
  this: Vue,
  input: string | (Error & { code?: string }),
  type?: AlertType,
  permanent?: boolean
) => void

export interface Alert {
  id: number
  type: AlertType
  message: string
  counter: number
}

@Component
export default class DisplaysAlerts extends Vue {
  private alerts: Alert[] = []

  @Provide()
  public showAlert(
    input: string | (Error & { code?: string }),
    type?: AlertType,
    permanent?: boolean
  ) {
    let message
    if (input instanceof Error) {
      message = input.code
        ? this.$te(`errors.${input.code}`)
          ? (this.$t(`errors.${input.code}`) as string)
          : input.code
        : input.message || input.toString()
      type = type || 'error'
    } else {
      message = input
      type = type || 'info'
    }

    const alert: Alert = {
      id: this.alerts.reduce((id, a) => (a.id === id ? id + 1 : id), 1),
      message,
      type,
      counter: 100
    }

    this.alerts.push(alert)

    if (!permanent) {
      const countdown = setInterval(() => {
        alert.counter = alert.counter - 3
        if (alert.counter <= 0) {
          this.alerts = this.alerts.filter(item => item.id !== alert.id)
          clearInterval(countdown)
        }
      }, 100)
    }
  }
}
