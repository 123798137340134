














import { Component, Prop, Vue } from 'nuxt-property-decorator'

interface Error {
  statusCode?: number
}

@Component({
  layout: 'empty',
  head(this: ErrorLayout) {
    return {
      title: this.error.statusCode === 404 ? this.pageNotFound : this.otherError
    }
  }
})
export default class ErrorLayout extends Vue {
  @Prop({
    type: Object,
    default: null
  })
  readonly error!: Error

  pageNotFound = '404 Not Found'
  otherError = 'An error occurred'
}
